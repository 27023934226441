import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
import { useNavigate } from "react-router-dom";
import "./signIn.css";
import apiService from "../../utils/api.mjs";

const SignIn = () => {
  // State variables
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false); // Add loading state
  const navigate = useNavigate();

  const handleSignIn = async (event) => {
    event.preventDefault();
    setError(""); // Reset error message
    setLoading(true); // Start loading

    let match = false;

    try {
      if (username.trim().length < 1 || !username.includes("@")) {
        setError("Invalid username or password");
        setLoading(false);
        return;
      } else {
        const response = await apiService.getStoreUserProfile(username);
        if (response.data.length === 0) {
          setError("Invalid username or password");
          setLoading(false);
          return;
        } else {
          for (let i = 0; i < response.data.length; i++) {
            if (username === response.data[i].email) {
              sessionStorage.setItem("store", response.data[i].store);
              let school_list = response.data[i].schools.join(",");
              sessionStorage.setItem("associatedSchools", school_list);
              match = true;
              break;
            }
          }
          if (!match) {
            setError("Invalid username or password");
            setLoading(false);
            return;
          }
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setError("An error occurred. Please try again.");
      setLoading(false);
      return;
    }

    try {
      const response = await apiService.signIn(username, password);
      sessionStorage.setItem("authToken", response.data.token);
      sessionStorage.setItem("userId", response.data.user_id);
      navigate("/dashboard");
    } catch (error) {
      console.error("Error Signing In:", error);
      setError("Invalid username or password");
    } finally {
      setLoading(false); // Stop loading after sign-in process
    }
  };

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const userId = sessionStorage.getItem("userId");
    const store = sessionStorage.getItem("store");
    const associatedSchools = sessionStorage.getItem("associatedSchools");

    if (authToken && userId && store && associatedSchools) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="custom-box"
      >
        {error && (
          <Typography variant="body1" style={{ color: "red", marginBottom: "16px" }}>
            {error}
          </Typography>
        )}
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              variant="standard"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="standard"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading} // Disable button when loading
            >
              Sign In
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SignIn;