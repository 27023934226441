import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api.mjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { FormControl, InputLabel, Chip } from "@mui/material";
import { format } from "date-fns";

const CreateMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const useLocationState = location.state?.record;

  // State variables
  const [error, setError] = useState(null); // General error message
  const [validationErrors, setValidationErrors] = useState([]); // Validation errors for food items
  const [menuName, setMenuName] = useState(useLocationState?.menuName || "");
  const [menuDescription, setMenuDescription] = useState(
    useLocationState?.menuDescription || ""
  );
  const [menuFromDate, setMenuFromDate] = useState(
    useLocationState?.menuFromDate ? dayjs(useLocationState.menuFromDate) : null
  );
  const [menuToDate, setMenuToDate] = useState(
    useLocationState?.menuToDate ? dayjs(useLocationState.menuToDate) : null
  );
  const [menuDeadlineDate, setMenuDeadlineDate] = useState(
    useLocationState?.menuOrderDeadlineDate ? dayjs(useLocationState.menuOrderDeadlineDate) : null
  );
  const [category, setCategory] = useState(
    useLocationState?.menuCategory || []
  );
  const [foodItem, setFoodItem] = useState(
    useLocationState?.menuFoodItem || []
  );
  const [menuSchool, setMenuSchool] = useState([]);
  const [allergens, setAllergens] = useState([]);

  const { id } = useParams();
  const [selectedCategories, setSelectedCategory] = useState(
    useLocationState?.menuCategory || [""]
  );
  const [selectedSchool, setSelectedSchool] = useState(
    useLocationState?.schoolId || ""
  );
  const [selectedFoodItem, setSelectedFoodItem] = useState(
    useLocationState?.menuFoodItem || [""]
  );

  // New state variables
  const [foodItemAvailability, setFoodItemAvailability] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    const associatedSchools = sessionStorage.getItem("associatedSchools");

    if (authToken) {
      // Fetch schools
      apiService
        .getSchool(authToken, undefined, associatedSchools)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              school: item.schoolName,
              id: item.schoolId,
            };
          });
          setMenuSchool(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });

      // Fetch categories
      apiService
        .getCategories(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              category: item.category,
              id: item.categoryId,
            };
          });
          setCategory(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });

      // Fetch food items
      apiService
        .getFoodItems(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              foodItem: item.foodItemName,
              allergens: item.foodItemAllergens,
              id: item.foodItemId,
            };
          });
          setFoodItem(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });

      // Fetch allergens
      apiService
        .getAllergens(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => {
            return {
              allergen: item.allergenName,
              id: item.allergenId,
            };
          });
          setAllergens(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate]);

  // Initialize foodItemAvailability when editing a menu
  useEffect(() => {
    if (useLocationState && useLocationState.menuFoodItemFull) {
      const availability = {};
      useLocationState.menuFoodItemFull.forEach((foodItem) => {
        const datesAvailable = foodItem.dates_available || [];
        const daysOfWeekSet = new Set();
        datesAvailable.forEach((dateStr) => {
          const date = new Date(dateStr);
          let dayOfWeek = date.getDay();
          // Adjust dayOfWeek to 1-based index (Monday=1, Sunday=0)
          if (dayOfWeek === 0) dayOfWeek = 7; // Sunday becomes 7
          if (dayOfWeek >= 1 && dayOfWeek <= 5) {
            daysOfWeekSet.add(dayOfWeek);
          }
        });
        const daysOfWeekArray = Array.from(daysOfWeekSet);
        availability[foodItem.foodItemId] = daysOfWeekArray;
      });
      setFoodItemAvailability(availability);

      // Check if all selected food items are available
      const allSelectedAvailable = selectedFoodItem.every(
        (id) => availability[id] && availability[id].length > 0
      );
      setSelectAll(allSelectedAvailable);
    }
  }, [useLocationState, selectedFoodItem]);

  // Sync foodItemAvailability with selectedFoodItem without overwriting existing data
  useEffect(() => {
    setFoodItemAvailability((prevAvailability) => {
      const newAvailability = { ...prevAvailability };
      selectedFoodItem.forEach((foodItemId) => {
        if (!newAvailability[foodItemId]) {
          newAvailability[foodItemId] = [];
        }
      });
      return newAvailability;
    });
  }, [selectedFoodItem]);

  const handleSelectSchool = (value) => {
    setSelectedSchool(value);
  };

  const handleSelectCategory = (value) => {
    if (value.includes("")) {
      const updatedCategories = value.filter((item) => item !== "");
      setSelectedCategory(updatedCategories);
    } else if (value.length === 0) {
      setSelectedCategory([""]);
    } else {
      setSelectedCategory(value);
    }
  };

  const handleSelectFoodItem = (value) => {
    if (value.includes("")) {
      const updateFoodItems = value.filter((item) => item !== "");
      setSelectedFoodItem(updateFoodItems);
    } else if (value.length === 0) {
      setSelectedFoodItem([""]);
    } else {
      setSelectedFoodItem(value);
    }
  };

  // Function to get dates between two dates for a specific day of the week
  const getDatesForDayOfWeek = (startDate, endDate, dayOfWeek) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      if (currentDate.getDay() === dayOfWeek) {
        dates.push(new Date(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Function to validate that each selected food item has at least one day selected
  const validateFoodItemAvailability = () => {
    const errors = [];
    selectedFoodItem.forEach((foodItemId) => {
      const availability = foodItemAvailability[foodItemId] || [];
      if (availability.length === 0) {
        const foodItemName =
          foodItem.find((item) => item.id === foodItemId)?.foodItem ||
          "Unknown Item";
        errors.push(`Please select at least one day for "${foodItemName}".`);
      }
    });
    return errors;
  };

  const handleCreateFoodItem = async (e) => {
    e.preventDefault();
    setError(null); // Reset general error
    setValidationErrors([]); // Reset validation errors

    // Perform validation
    const availabilityErrors = validateFoodItemAvailability();
    if (availabilityErrors.length > 0) {
      setValidationErrors(availabilityErrors);
      return; // Prevent form submission
    }

    let menuFDate = menuFromDate;
    let menuTDate = menuToDate;
    let menuDDate = menuDeadlineDate;

    if (menuToDate && menuToDate.isValid()) {
      menuTDate = format(menuToDate.toDate(), "yyyy-MM-dd") + "T00:00:00Z";
    }
    if (menuDeadlineDate && menuDeadlineDate.isValid()) {
      menuDDate = format(menuDeadlineDate.toDate(), "yyyy-MM-dd") + "T23:59:59Z";
    }
    if (menuFromDate && menuFromDate.isValid()) {
      menuFDate = format(menuFromDate.toDate(), "yyyy-MM-dd") + "T00:00:00Z";
    }

    if (menuDDate > menuFDate) {
      setError("Deadline date should be before From Date");
      return;
    } else if (menuFDate > menuTDate) {
      setError("From date should be before To Date");
      return;
    }

    // Prepare availabilityData
    const availabilityData = [];
    const fromDate = new Date(menuFDate);
    const toDate = new Date(menuTDate);

    selectedFoodItem.forEach((foodItemId) => {
      const daysOfWeek = foodItemAvailability[foodItemId] || [];
      daysOfWeek.forEach((dayOfWeek) => {
        const jsDayOfWeek = dayOfWeek % 7;
        const dates = getDatesForDayOfWeek(fromDate, toDate, jsDayOfWeek);
        dates.forEach((date) => {
          const dateStr = format(date, "yyyy-MM-dd");
          availabilityData.push({
            foodItemId: foodItemId,
            date: dateStr,
          });
        });
      });
    });

    try {
      const authToken = sessionStorage.getItem("authToken");
      const store = sessionStorage.getItem("store");

      if (authToken) {
        if (useLocationState) {
          // Check if order_count is greater than 0
          if (useLocationState.order_count > 0) {
            // If order_count > 0, you might want to disable updates or handle accordingly
            // For now, let's assume you just navigate back
            navigate("/menus");
            return;
          }
          // Update existing menu
          await apiService.updateMenu(
            authToken,
            id,
            menuName,
            menuDescription,
            selectedSchool,
            selectedCategories,
            selectedFoodItem,
            menuFDate,
            menuTDate,
            menuDDate,
            store,
            availabilityData
          );
        } else {
          // Create a new menu
          await apiService.createMenu(
            authToken,
            menuName,
            menuDescription,
            selectedSchool,
            selectedCategories,
            selectedFoodItem,
            menuFDate,
            menuTDate,
            menuDDate,
            store,
            availabilityData
          );
        }
        navigate("/menus");
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while creating the menu.");
    }
  };

  // Handle checkbox changes in the availability table
  const handleAvailabilityChange = (foodItemId, dayOfWeek, checked) => {
    setFoodItemAvailability((prevAvailability) => {
      const newAvailability = { ...prevAvailability };
      if (!newAvailability[foodItemId]) {
        newAvailability[foodItemId] = [];
      }
      if (checked) {
        if (!newAvailability[foodItemId].includes(dayOfWeek)) {
          newAvailability[foodItemId].push(dayOfWeek);
        }
      } else {
        newAvailability[foodItemId] = newAvailability[foodItemId].filter(
          (d) => d !== dayOfWeek
        );
      }
      return newAvailability;
    });
  };

  // Handle select all checkbox
  const handleSelectAll = (checked) => {
    setSelectAll(checked);
    const allDays = [1, 2, 3, 4, 5]; // Monday to Friday
    const newAvailability = {};

    if (checked) {
      selectedFoodItem.forEach((foodItemId) => {
        newAvailability[foodItemId] = [...allDays];
      });
    } else {
      selectedFoodItem.forEach((foodItemId) => {
        newAvailability[foodItemId] = [];
      });
    }

    setFoodItemAvailability(newAvailability);
  };

  // Function to compute day counts
  const computeDayCounts = () => {
    const dayCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }; // Monday to Friday
    Object.values(foodItemAvailability).forEach((days) => {
      days.forEach((day) => {
        if (dayCounts[day] !== undefined) {
          dayCounts[day] += 1;
        }
      });
    });
    return dayCounts;
  };

  const dayCounts = computeDayCounts();

  return (
    <Container>
      <ViewHeader
        screenName={useLocationState ? "Edit Menu" : "Create Menu"}
      />
      <Container maxWidth="m">
        <Box
          display="flex"
          flexDirection="column"
          component={"form"}
          className="custom-box"
          onSubmit={handleCreateFoodItem}
        >
          {error && (
            <Typography variant="body1" style={{ color: "red" }}>
              {error}
            </Typography>
          )}
          {validationErrors.length > 0 && (
            <Box style={{ marginBottom: "16px" }}>
              {validationErrors.map((err, index) => (
                <Typography key={index} variant="body2" style={{ color: "red" }}>
                  {err}
                </Typography>
              ))}
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
          >
            <TextField
              label="Menu"
              variant="outlined"
              margin="normal"
              required
              disabled={(useLocationState?.order_count ?? 0) > 0}
              fullWidth={false}
              autoFocus
              onChange={(e) => setMenuName(e.target.value)}
              value={menuName}
            />
            {menuSchool.length > 0 && (
              <FormControl sx={{ marginRight: 2, minWidth: "200px" }}>
                <InputLabel id="school-label">School</InputLabel>
                <Select
                  labelId="school-label"
                  value={selectedSchool}
                  disabled={(useLocationState?.order_count ?? 0) > 0}
                  onChange={(e) => {
                    handleSelectSchool(e.target.value);
                  }}
                  label="School" // This ensures the label is correctly associated
                >
                  {/* Added key to static MenuItem */}
                  <MenuItem key="select-school" value="" style={{ display: "none" }}>
                    Select School
                  </MenuItem>
                  {menuSchool.map((i) => (
                    <MenuItem key={i?.id} value={i?.id}>
                      {i?.school}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Typography gutterBottom style={{ margin: "16px" }}>
              Add Dates available
            </Typography>
            <Typography
              gutterBottom
              style={{ marginTop: "16px", paddingLeft: "8px" }}
            >
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <DatePicker
              label="From Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              value={menuFromDate}
              onChange={(e) => {
                setMenuFromDate(e);
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: "200px", height: "50px" }} />}
            />
            <DatePicker
              label="To Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              value={menuToDate}
              onChange={(e) => setMenuToDate(e)}
              renderInput={(params) => <TextField {...params} sx={{ width: "200px", height: "50px" }} />}
            />
            <DatePicker
              label="Deadline Date"
              variant="outlined"
              disabled={(useLocationState?.order_count ?? 0) > 0}
              margin="normal"
              value={menuDeadlineDate}
              onChange={(e) => setMenuDeadlineDate(e)}
              renderInput={(params) => <TextField {...params} sx={{ width: "200px", height: "50px" }} />}
            />
          </Box>
          <Typography
            gutterBottom
            style={{ margin: "16px", paddingTop: "20px" }}
          >
            Add Food Items to Menu
          </Typography>
          <Box display="flex" justifyContent="flex-start" flexDirection="row">
            <Box>
              <FormControl>
                <InputLabel id="cat-label">Category</InputLabel>
                <Select
                  multiple
                  value={selectedCategories}
                  disabled={(useLocationState?.order_count ?? 0) > 0}
                  onChange={(e) => {
                    handleSelectCategory(e.target.value);
                  }}
                  label="Category"
                  labelId="cat-label"
                  sx={{ marginRight: 2, width: "200px" }}
                >
                  {/* Added key to static MenuItem */}
                  <MenuItem key="select-category" value="" style={{ display: "none" }}>
                    Select Category
                  </MenuItem>
                  {category.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="fi-label">Food Items</InputLabel>
                <Select
                  multiple
                  value={selectedFoodItem}
                  disabled={(useLocationState?.order_count ?? 0) > 0}
                  onChange={(e) => handleSelectFoodItem(e.target.value)}
                  label="Food Item"
                  labelId="fi-label"
                  sx={{ marginRight: 2, width: "200px" }}
                >
                  {/* Added key to static MenuItem */}
                  <MenuItem key="select-food-item" value="" style={{ display: "none" }}>
                    Select Food Item
                  </MenuItem>
                  {foodItem.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.foodItem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* Select All checkbox */}
          <Box
            style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <Checkbox
              checked={selectAll}
              onChange={(e) => handleSelectAll(e.target.checked)}
            />
            <Typography
              variant="body1"
              onClick={() => handleSelectAll(!selectAll)}
              style={{ cursor: "pointer" }}
            >
              Select All
            </Typography>
          </Box>

          {/* Availability Table */}
          {selectedFoodItem.length > 0 && selectedFoodItem[0] !== "" && (
            <>
              <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Food Item</TableCell>
                      {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map(
                        (day, index) => {
                          const dayIndex = index + 1; // Days are 1-indexed (Monday=1)
                          return (
                            <TableCell
                              key={index}
                              align="center"
                              style={{ minWidth: 100 }}
                            >
                              <Typography variant="subtitle1">{day}</Typography>
                              <Typography variant="caption" color="textSecondary">
                                ({dayCounts[dayIndex] || 0})
                              </Typography>
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedFoodItem.map((foodItemId) => {
                      const foodItems = foodItem.find(
                        (item) => item.id === foodItemId
                      );

                      if (!foodItems) {
                        return null;
                      }

                      const availability = foodItemAvailability[foodItemId] || [];

                      // Get allergen names
                      const allergenList = foodItems.allergens
                        .map((allergenId) => {
                          const allergen = allergens.find((i) => i.id === allergenId);
                          return allergen ? allergen.allergen : null;
                        })
                        .filter(Boolean);

                      // Check if there's a validation error for this food item
                      const hasValidationError = validationErrors.some((err) =>
                        err.includes(foodItems.foodItem)
                      );

                      return (
                        <TableRow
                          key={foodItems.id}
                          style={{
                            backgroundColor: hasValidationError ? "#ffcccc" : "inherit",
                          }}
                        >
                          <TableCell>
                            <Typography variant="subtitle2">
                              {foodItems.foodItem}
                            </Typography>
                            {allergenList.length > 0 ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                  marginTop: 0.5,
                                }}
                              >
                                {allergenList.map((allergen, index) => (
                                  <Chip key={index} label={allergen} size="small" />
                                ))}
                              </Box>
                            ) : (
                              <Typography variant="caption" color="textSecondary">
                                No allergens
                              </Typography>
                            )}
                          </TableCell>
                          {[1, 2, 3, 4, 5].map((dayOfWeek) => (
                            <TableCell
                              key={dayOfWeek}
                              align="center"
                              style={{ minWidth: 100 }}
                            >
                              <Checkbox
                                checked={availability.includes(dayOfWeek)}
                                onChange={(e) =>
                                  handleAvailabilityChange(
                                    foodItemId,
                                    dayOfWeek,
                                    e.target.checked
                                  )
                                }
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          <Typography gutterBottom style={{ marginTop: "8px" }}>
            Add Description
          </Typography>
          <TextField
            label="Description"
            multiline
            rows={4}
            variant="outlined"
            margin="normal"
            required
            disabled={(useLocationState?.order_count ?? 0) > 0}
            value={menuDescription}
            onChange={(e) => setMenuDescription(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            style={{ marginTop: "20px" }}
          >
            {useLocationState
              ? useLocationState.order_count > 0
                ? "Close"
                : "Update"
              : "Add"}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default CreateMenu;