import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.lunchboxlarry.com/" /*  "http://localhost:8000/", */,
  timeout: 120000,
});

const apiService = {
  signIn: (username, password) => instance.post("/login/", { username: username, password: password }),
  getStoreUserProfile: (email) => {
    return instance.get(`/storeuserprofile/?email=${email}`);
  },
  getCategories: (authToken, id = 0) => {
    if (id > 0) {
      return instance.get(`/category/?categoryid=${id}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
    return instance.get("/category/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  createCatergory: (authToken, name, description, storeID) => {
    return instance.post(
      "/category/create/",
      {
        category: name,
        categoryDescription: description,
        categoryStore: storeID,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  updateCategory: (authToken, name, description, storeID, categoryID) => {
    return instance.patch(
      `/category/${categoryID}/edit/`,
      {
        category: name,
        categoryDescription: description,
        categoryStore: storeID,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  deleteCategory: (authToken, categoryID) => {
    return instance.delete(`/category/${categoryID}/delete/`, {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getAllergens: (authToken) => {
    return instance.get("/allergens/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  createAllergen: (authToken, name, description) => {
    return instance.post(
      "/allergens/create/",
      {
        allergenName: name,
        allergenDescription: description,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  updateAllergen: (authToken, name, description, allergenID) => {
    return instance.patch(
      `/allergens/${allergenID}/edit/`,
      {
        allergenId: allergenID,
        allergenName: name,
        allergenDescription: description,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  deleteAllergen: (authToken, allergenID) => {
    return instance.delete(`/allergens/${allergenID}/delete/`, {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getFoodItems: (authToken, id = 0) => {
    const store = sessionStorage.getItem("store");
    const params = new URLSearchParams();

    if (store) {
      params.append("store", store);
    }

    if (id > 0) {
      params.append("foodItemId", id);
    }

    const url = `/fooditem/?${params.toString()}`;

    return instance.get(url, {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  createFoodItem: (authToken, formData) => {
    return instance.post("/fooditem/create/", formData, {
      headers: {
        Authorization: `Token ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateFoodItems: (authToken, formData, foodItemID) => {
    return instance.patch(`/fooditem/${foodItemID}/edit/`, formData, {
      headers: {
        Authorization: `Token ${authToken}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteFoodItem: (authToken, foodItemID) => {
    return instance.delete(`/fooditem/${foodItemID}/delete/`, {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getMenus: (authToken, currentDT = null, menuSchool = null) => {
    if (currentDT != null && menuSchool != null) {
      return instance.get(`/menu/?currentDT=${currentDT}&menuSchoolList=${menuSchool}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else if (currentDT == null && menuSchool != null) {
      return instance.get(`/menu/?menuSchoolList=${menuSchool}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else {
      return instance.get("/menu/", {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
  },
  createMenu: (authToken, name, description, schoolID, categoryID, foodItemID, fromDate, toDate, deadlineDate, storeID,availabilityData) => {
    console.log("availabilityData", availabilityData);
    return instance.post(
      "/menu/create/",
      {
        menuName: name,
        menuDescription: description,
        menuSchool: schoolID,
        menuCategory: categoryID,
        menuFoodItem: foodItemID,
        menuFromDate: fromDate,
        menuToDate: toDate,
        menuOrderDeadlineDate: deadlineDate,
        menuStore: storeID,
        availabilityData: availabilityData,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  updateMenu: (authToken, menuID, name, description, schoolID, categoryID, foodItemID, fromDate, toDate, deadlineDate, storeID,availabilityData) => {
    return instance.patch(
      `/menu/${menuID}/edit/`,
      {
        menuName: name,
        menuDescription: description,
        menuSchool: schoolID,
        menuCategory: categoryID,
        menuFoodItem: foodItemID,
        menuFromDate: fromDate,
        menuToDate: toDate,
        menuOrderDeadlineDate: deadlineDate,
        availabilityData: availabilityData,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  deleteMenu: (authToken, menuID) => {
    return instance.delete(`/menu/${menuID}/delete/`, {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getSchool: (authToken, id = 0, schoolList = null) => {
    if (id > 0) {
      return instance.get(`/school/?schoolId=${id}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else if (schoolList != null) {
      return instance.get(`/school/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else {
      return instance.get("/school/", {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
  },
  getStudents: (authToken) => {
    return instance.get("/student/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getCounty: (authToken, id = 0) => {
    if (id > 0) {
      return instance.get(`/county/?countyid=${id}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
    return instance.get("/county/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getClass: (authToken, id = 0) => {
    if (id > 0) {
      return instance.get(`/classes/?classId=${id}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
    return instance.get("/classes/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getOrders: (authToken) => {
    return instance.get("/order/", {
      headers: { Authorization: `Token ${authToken}` },
    });
  },
  getOrdersDetail: (authToken, orderDate = null, orderClass = null, foodItem = null, school = null, schoolList = null) => {
    if (school !== 0) {
      schoolList = school;
    }
    if (orderDate != null && orderClass != null && foodItem != null && schoolList != null) {
      return instance.get(`/orderlist/?orderDate=${orderDate}&classId=${orderClass}&foodItemId=${foodItem}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else if (orderDate != null && schoolList != null) {
      return instance.get(`/orderlist/?orderDate=${orderDate}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else if (orderClass != null && schoolList != null) {
      return instance.get(`/orderlist/?classId=${orderClass}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else if (schoolList != null) {
      return instance.get(`/orderlist/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else {
      return instance.get("/orderlist/", {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
  },
  createOrder: (authToken, studentID, studentName, orderDate, userID, menuID, orderStore, schoolID, foodItemID) => {
    return instance.post(
      "/order/store/create/",
      {
        orderStudent: studentID,
        orderStudentName: studentName,
        orderDate: orderDate,
        orderUser: userID,
        orderMenu: menuID,
        orderStore: orderStore,
        orderSchool: schoolID,
        orderFoodItem: foodItemID,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  updateOrder: (authToken, orderID, studentID, studentName, orderDate, userID, menuID, orderStore, schoolID, foodItemID) => {
    return instance.patch(
      `/order/${orderID}/edit/`,
      {
        orderStudent: studentID,
        orderStudentName: studentName,
        orderDate: orderDate,
        orderUser: userID,
        orderMenu: menuID,
        orderStore: orderStore,
        orderSchool: schoolID,
        orderFoodItem: foodItemID,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  getUsers(authToken, schoolList = null) {
    if (schoolList != null) {
      return instance.get(`/authuserlist/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${authToken}` },
      });
    } else {
      return instance.get("/authuserlist/", {
        headers: { Authorization: `Token ${authToken}` },
      });
    }
  },
  createUser(authToken, firstname, lastname, email, phoneNo, county, school) {
    return instance.post(
      "/authusers/create/",
      {
        authorisedUserFirstName: firstname,
        authorisedUserLastName: lastname,
        authorisedUserEmail: email,
        authorisedUserPhoneNo: phoneNo,
        authorisedUserSchool: school,
        authorisedUserCounty: county,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  updateUser(authToken, firstname, lastname, email, phoneNo, school, county, id) {
    return instance.patch(
      `/authusers/${id}/edit/`,
      {
        authorisedUserFirstName: firstname,
        authorisedUserLastName: lastname,
        authorisedUserEmail: email,
        authorisedUserPhoneNo: phoneNo,
        authorisedUserSchool: school,
        authorisedUserCounty: county,
      },
      {
        headers: { Authorization: `Token ${authToken}` },
      }
    );
  },
  resetPassword(token, password, email) {
    return instance.put("/password-reset/", {
      token: token,
      password: password,
      email: email,
    });
  },
  creataClosedDay(token, fromDate, toDate, school, reason) {
    return instance.post(
      "/schoolcalendar/create/",
      {
        calendarFromDate: fromDate,
        calendarToDate: toDate,
        calendarSchoolId: school,
        calendarReason: reason,
      },
      {
        headers: { Authorization: `Token ${token}` },
      }
    );
  },
  getClosedDays(token, schoolsList = null) {
    if (schoolsList != null) {
      return instance.get(`/schoolcalendar/?schoolList=${schoolsList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else {
      return instance.get("/schoolcalendar/", {
        headers: { Authorization: `Token ${token}` },
      });
    }
  },
  deleteClosedDay(token, id) {
    return instance.delete(`/schoolcalendar/${id}/delete/`, {
      headers: { Authorization: `Token ${token}` },
    });
  },
  getOrderItemCount(token, orderDate = null, orderClass = null, foodItem = null, school = null, schoolList = null) {
    if (school !== 0) {
      schoolList = school;
    }
    if (orderDate != null && orderClass != null && foodItem != null && schoolList != null) {
      return instance.get(`/orderitemcount/?orderDate=${orderDate}&orderClass=${orderClass}&orderFoodItem=${foodItem}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else if (orderDate != null && schoolList != null) {
      return instance.get(`/orderitemcount/?orderDate=${orderDate}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else if (orderClass != null && schoolList != null) {
      return instance.get(`/orderitemcount/?orderClass=${orderClass}&schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else if (schoolList != null) {
      return instance.get(`/orderitemcount/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else {
      return instance.get("/orderitemcount/", {
        headers: { Authorization: `Token ${token}` },
      });
    }
  },
  getUserProfiles(token, schoolList = null) {
    if (schoolList != null) {
      return instance.get(`/userprofile/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else {
      return instance.get("/userprofile/", {
        headers: { Authorization: `Token ${token}` },
      });
    }
  },
  getStudentsList: (token, schoolList = null) => {
    console.log("schoolList", schoolList);
    if (schoolList !== null) {
      return instance.get(`/student/?schoolList=${schoolList}`, {
        headers: { Authorization: `Token ${token}` },
      });
    } else {
      return instance.get("/student/", {
        headers: { Authorization: `Token ${token}` },
      });
    }
  },
  updateStudentsClass: (authToken, studentIds, newClassId) => {
    return instance.patch(
      `/students/update_class/`,
      { studentIds, newClassId },
      { headers: { Authorization: `Token ${authToken}` } }
    );
  },
  updateStudent: (token, id, data) => {
    return instance.patch(`/student/${id}/edit/`, data, {
      headers: { Authorization: `Token ${token}` },
    });
  },
  deleteStudent: (authToken, studentId) => {
    return instance.delete(`/student/${studentId}/delete/`, {
      headers: {
        Authorization: `Token ${authToken}`,
      },
    });
  },
};

export default apiService;