import React, { useState, useEffect } from "react";
import ViewHeader from "../../viewHeader/viewHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import apiService from "../../../utils/api";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { InputLabel, FormControl, Tooltip } from "@mui/material";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const useLocationState = location.state?.record;

  const [error, setError] = useState("");
  // State
  const [foodItem, setFoodItem] = useState(useLocationState?.foodItemName || "");
  const [categories, setCategory] = useState([]);
  const [allergens, setAllergen] = useState([]);
  const [ingredients, setIngredients] = useState(useLocationState?.foodItemIngredients || "");
  const [energKj, setEnergyKj] = useState(useLocationState?.foodItemEnergyKj || "");
  const [energKcal, setEnergyKcal] = useState(useLocationState?.foodItemEnergyKcal || "");
  const [fat, setFat] = useState(useLocationState?.foodItemFat || "");
  const [saturates, setSaturates] = useState(useLocationState?.foodItemSaturates || "");
  const [carbs, setCarbs] = useState(useLocationState?.foodItemCarbs || "");
  const [sugars, setSugars] = useState(useLocationState?.foodItemSugars || "");
  const [fibre, setFibre] = useState(useLocationState?.foodItemFibre || "");
  const [protein, setProtein] = useState(useLocationState?.foodItemProtein || "");
  const [salt, setSalt] = useState(useLocationState?.foodItemSalt || "");
  const [comments, setComments] = useState(useLocationState?.foodItemComment || "");

  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(useLocationState?.foodItemPhoto || "");
  const [selectedCategories, setSelectedCategory] = useState(useLocationState?.foodItemCategory || []);
  const [selectedAllergens, setSelectedAllergen] = useState(useLocationState?.foodItemAllergens || []);

  useEffect(() => {
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) {
      // Fetch Categories
      apiService
        .getCategories(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => ({
            category: item.category,
            id: item.categoryId, // Correctly use categoryId
          }));
          setCategory(filteredData);
        })
        .catch((error) => {
          console.error(error);
        });

      // Fetch Allergens
      apiService
        .getAllergens(authToken)
        .then((response) => {
          const filteredData = response.data.map((item) => ({
            allergen: item.allergenName,
            id: item.allergenId, // Correctly use allergenId
          }));
          setAllergen(filteredData);
          console.log(useLocationState);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      navigate("/");
    }
  }, [navigate, useLocationState]);

  // Handle File Change with Size and Format Validation
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif", "image/bmp"];
      if (!validTypes.includes(file.type)) {
        setError("Invalid file format. Please upload an image in JPG, PNG, GIF, or BMP format.");
        setSelectedFile(null);
        setPreviewUrl(useLocationState?.foodItemPhoto || "");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        setError("File size exceeds 2MB.");
        setSelectedFile(null);
        setPreviewUrl(useLocationState?.foodItemPhoto || "");
      } else {
        setSelectedFile(file);
        setError("");
        // Optional: Preview the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCreateFoodItem = async (e) => {
    e.preventDefault();

    try {
      const authToken = sessionStorage.getItem("authToken");
      const store = sessionStorage.getItem("store");
      if (authToken) {
        const formData = new FormData();
        formData.append("foodItemName", foodItem);

        selectedCategories.forEach((category) => {
          formData.append("foodItemCategory", category);
        });

        selectedAllergens.forEach((allergen) => {
          formData.append("foodItemAllergens", allergen);
        });

        formData.append("foodItemIngredients", ingredients);
        formData.append("foodItemEnergyKj", energKj);
        formData.append("foodItemEnergyKcal", energKcal);
        formData.append("foodItemFat", fat);
        formData.append("foodItemSaturates", saturates);
        formData.append("foodItemCarbohydrates", carbs);
        formData.append("foodItemSugars", sugars);
        formData.append("foodItemFibre", fibre);
        formData.append("foodItemProtein", protein);
        formData.append("foodItemSalt", salt);
        formData.append("foodItemComment", comments);
        formData.append("foodItemStore", store);

        if (selectedFile) {
          formData.append("foodItemPhoto", selectedFile);
        }

        const response = useLocationState
          ? await apiService.updateFoodItems(authToken, formData, id)
          : await apiService.createFoodItem(authToken, formData);

        navigate("/fooditems/");
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(error.response?.data?.error || "An error occurred.");
    }
  };

  const handleSelectCategory = (value) => {
    setSelectedCategory(value);
  };

  const handleSelectAllergen = (value) => {
    setSelectedAllergen(value);
  };

  return (
    <Container>
      <ViewHeader screenName={useLocationState ? "Edit Food Item" : "Create Food Item"} />
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="column"
          component={"form"}
          className="custom-box"
          onSubmit={handleCreateFoodItem}
          noValidate
          sx={{ mt: 3 }}
        >
          {error && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
          <TextField
            label="Food Item"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            autoFocus
            onChange={(e) => setFoodItem(e.target.value)}
            value={foodItem}
            inputProps={{ maxLength: 100 }}
            disabled={!!useLocationState}
          />

          {/* Unified Photo Upload Section */}
          <Box mt={2}>
            <Box display="flex" alignItems="center">
              <label htmlFor="upload-photo" style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  component="span"
                  startIcon={<CloudUploadIcon />}
                >
                  {previewUrl ? "Change Photo" : "Upload Photo"}
                </Button>
              </label>
              <Tooltip
                title="Upload a square image in JPG, PNG, GIF, or BMP format. Maximum size 2MB."
                arrow
                placement="top"

              >
                <InfoOutlinedIcon
                  sx={{ ml: 1,mt:0.3, cursor: "pointer", color: "text.secondary" }}
                  fontSize="medium"
                />
              </Tooltip>
            </Box>
            <input
              type="file"
              accept="image/*"
              id="upload-photo"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {previewUrl && (
              <Box mt={2}>
                <Typography variant="subtitle1">Photo Preview:</Typography>
                <Box
                  component="img"
                  src={previewUrl}
                  alt={foodItem}
                  sx={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                  }}
                />
              </Box>
            )}
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected File: {selectedFile.name}
              </Typography>
            )}
          </Box>

          <Box display="flex" justifyContent="space-between" flexDirection="row" mt={2}>
            <FormControl variant="outlined" sx={{ width: "48%" }}>
              <InputLabel id="cat-label">Category</InputLabel>
              <Select
                labelId="cat-label"
                multiple
                value={selectedCategories}
                onChange={(e) => handleSelectCategory(e.target.value)}
                label="Category"
                renderValue={(selected) =>
                  categories
                    .filter((category) => selected.includes(category.id))
                    .map((cat) => cat.category)
                    .join(", ")
                }
              >
                {categories.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ width: "48%" }}>
              <InputLabel id="all-label">Allergen</InputLabel>
              <Select
                labelId="all-label"
                multiple
                value={selectedAllergens}
                onChange={(e) => handleSelectAllergen(e.target.value)}
                label="Allergen"
                disabled={!!useLocationState}
                renderValue={(selected) =>
                  allergens
                    .filter((allergen) => selected.includes(allergen.id))
                    .map((all) => all.allergen)
                    .join(", ")
                }
              >
                {allergens.map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.allergen}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* File Upload Section */}
          {/* (Removed separate current and selected photo previews) */}

          {/* Add Description & Ingredients */}
          <Box mt={3}>
            <Typography gutterBottom>Add Description & Ingredients</Typography>
            <TextField
              label="Add Ingredients Here"
              variant="outlined"
              margin="normal"
              fullWidth
              required
              multiline
              rows={4}
              value={ingredients}
              onChange={(e) => setIngredients(e.target.value)}
            />
          </Box>

          {/* Nutritional Information */}
          <Box display="flex" justifyContent="space-between" flexDirection="row" mt={2}>
            <TextField
              label="Energy (Kilojoules)"
              variant="outlined"
              margin="normal"
              value={energKj}
              onChange={(e) => setEnergyKj(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Energy (Kcal)"
              variant="outlined"
              margin="normal"
              value={energKcal}
              onChange={(e) => setEnergyKcal(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Fat (g)"
              variant="outlined"
              margin="normal"
              value={fat}
              onChange={(e) => setFat(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" flexDirection="row" mt={2}>
            <TextField
              label="Saturated Fat (g)"
              variant="outlined"
              margin="normal"
              value={saturates}
              onChange={(e) => setSaturates(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Carbohydrates (g)"
              variant="outlined"
              margin="normal"
              value={carbs}
              onChange={(e) => setCarbs(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Sugars (g)"
              variant="outlined"
              margin="normal"
              value={sugars}
              onChange={(e) => setSugars(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Box>

          <Box display="flex" justifyContent="space-between" flexDirection="row" mt={2}>
            <TextField
              label="Fibre (g)"
              variant="outlined"
              margin="normal"
              value={fibre}
              onChange={(e) => setFibre(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Protein (g)"
              variant="outlined"
              margin="normal"
              value={protein}
              onChange={(e) => setProtein(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
            <TextField
              label="Salt (g)"
              variant="outlined"
              margin="normal"
              value={salt}
              onChange={(e) => setSalt(e.target.value)}
              sx={{ width: "30%" }}
              type="number"
              inputProps={{ min: 0 }}
            />
          </Box>

          {/* Add Comments */}
          <Box mt={3}>
            <Typography gutterBottom>Add Comments</Typography>
            <TextField
              label="Add Comments Here"
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={3}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </Box>

          <Button variant="contained" color="primary" fullWidth type="submit" sx={{ mt: 3 }}>
            {useLocationState ? "Update" : "Add"}
          </Button>
        </Box>
      </Container>
    </Container>
  );
};

export default CreateFoodItems;
